<template>
  <div class="answer-css">
    <!-- <Header :headerColor="headerColor" /> -->
    <div class="answer">
      <div class="grade-box">
        <img src="../../assets/evaluation/img9.png" />
        <div class="grade">
          <div v-for="(item, index) in LevelsList" :key="index">
            <div
              class="item"
              :class="[
                  {
                    activeBlue: levelsIndex > index,
                    activeOrage: levelsIndex == index,
                  },
                ]"
            >
              <span>{{ item.levels }}</span>
              <img src="../../assets/evaluation/lock.png" v-if="levelsIndex < index" />
              <!-- v-if="index != 0 && !item.over && levelsIndex != index" -->
            </div>
            <div class="background" v-if="index != LevelsList.length - 1"></div>
          </div>
        </div>
      </div>
      <div class="answer-box">
        <div class="title">
          <p>{{ levels }}答题表</p>
          <div class="info">
            <div>
              <span class="poin poin1"></span>
              <span>未完成</span>
            </div>
            <div>
              <span class="poin poin2"></span>
              <span>已完成</span>
            </div>
            <div>
              <span class="poin poin3"></span>
              <span>当前正处于</span>
            </div>
          </div>
        </div>
        <div class="upgrade">
          <div class="item" v-for="(item, index) in typeList" :key="index">
            <div
              class="text"
              @click="clickType(item.sort, index)"
              :class="[
                  {
                    activeBlue: item.checkTypeNumber == item.number,
                    activeOrage: typeIndex == index,
                  },
                ]"
            >
              <span v-if="index != 0"></span>
              <div>
                <span>{{ item.sort }}</span>
                <span>{{ item.checkTypeNumber }}/{{ item.number }}</span>
              </div>
            </div>
            <span
              class="triangle"
              :class="{
                  triangleeBlue: item.checkTypeNumber == item.number,
                  triangleeOrage: index == typeIndex,
                }"
            ></span>
          </div>
        </div>
        <div class="question-box">
          <div class="item" id="answer-contain">
            <div v-for="(item, index) in answerList" :key="index">
              <p>{{ item.subdomainName }}</p>
              <div
                class="contian"
                v-for="(child, childIndex) in item.options"
                :key="childIndex"
                :class="{ diabaleColor: child.cutStatus == 1 }"
              >
                <p>{{ child.title }}</p>
                <div v-for="(son, sonIndex) in child.lstOptions" :key="sonIndex" class="box">
                  <span class="checked" v-if="son.checked"></span>
                  <div
                    class="text"
                    @click="
                        clickText(
                          child.lstOptions,
                          son,
                          child.title,
                          child.answerGuId,
                          child.cutStatus
                        )
                      "
                  >{{ son.options }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-box">
          <el-button type="primary" @click="keepData">保存</el-button>
          <el-button type="warning" @click="submitData">提交</el-button>
          <router-link tag="el-button" to="./historypage" type="info" plain>退出</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { get, post, put } from "../../api/http";
// import Header from "../../components/Header";
export default {
  components: {
    // Header,
  },
  data() {
    return {
      headerColor: "#5A8BFF",
      // 等级
      LevelsList: [],
      // 按等级类型获取信息
      levels: "",
      levelsIndex: 0,
      sort: "",
      answerList: [],
      typeList: [], //类型
      typeIndex: 0,
      spareTire: 0,
      checkedList: [], //选中的问答信息
      dataLength: 0, //某级所有数据的长度
      stopExecution: false,
    };
  },
  mounted() {
    // this.getLevelsAll();
    // this.getTypeInfoAll();
  },
  methods: {
    getLevelsIndex(levels) {
      if (levels == "一级") {
        this.levelsIndex = 0;
      } else if (levels == "二级") {
        this.levelsIndex = 1;
      } else if (levels == "三级") {
        this.levelsIndex = 2;
      } else if (levels == "四级") {
        this.levelsIndex = 3;
      } else if (levels == "五级") {
        this.levelsIndex = 4;
      }
    },
    // 获取所有等级
    getLevelsAll() {
      get("/QuestionBank/Levels")
        .then((resp) => {
          if (resp.data.code == 200) {
            this.LevelsList = resp.data.data;
          }
        })
        .catch();
    },
    // 按公司获取某级及某级的所有分类、信息
    getTypeInfoAll() {
      get("/AnswerBank", {
        CompName: JSON.parse(localStorage.getItem("userNo")),
      })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.$message({
              message: "暂无答题内容",
              type: "warning",
            });
            this.$router.go(-1);
            return;
          }
          this.levels = resp.data.data[0].levels;
          this.getLevelsIndex(resp.data.data[0].levels);
          this.dataLength = resp.data.data.length;
          this.typeList = [];
          this.checkedList = [];
          resp.data.data.map((item) => {
            this.typeList.push({ sort: item.sort, checkTypeNumber: 0 });
            // 保存以后，计算已保存项的个数
            if (item.selectValue != "") {
              this.typeList.map((typeItem) => {
                if (item.sort == typeItem.sort) {
                  typeItem.checkTypeNumber = typeItem.checkTypeNumber + 1;
                }
              });
              this.checkedList.push({
                AnswerGuId: item.answerGuId,
                title: item.title,
                SelectValue: item.selectValue,
              });
            }
            // 将不可选的项放进提交参数的列表
            if (item.cutStatus == 1) {
              this.checkedList.push({
                AnswerGuId: item.answerGuId,
                title: item.title,
                SelectValue: "",
              });
            }
          });
          // 去重复对象
          var result = [];
          var obj = {};
          for (var i = 0; i < this.typeList.length; i++) {
            if (!obj[this.typeList[i].sort]) {
              result.push(this.typeList[i]);
              obj[this.typeList[i].sort] = true;
            }
          }
          this.typeList = result;

          //计算元素信息条数
          this.typeList.map((typeItem) => {
            typeItem.number = 0;
            resp.data.data.map((item) => {
              if (typeItem.sort == item.sort) {
                typeItem.number = typeItem.number + 1;
              }
            });
          });
          this.clickType(this.typeList[0].sort, 0);
        })
        .catch();
    },
    // 按等级分类查询题库信息
    clickType(sort, index) {
      document.getElementById("answer-contain").scrollTop = 0;
      this.typeIndex = index;
      this.sort = sort;
      get("/AnswerBank/GetByLevelsBySort", {
        CompName: JSON.parse(localStorage.getItem("userNo")),
        Levels: this.levels,
        Sort: sort,
      })
        .then((resp) => {
          if (resp.data.code == 200) {
            // 算出subdomainName的个数
            var list = [];
            resp.data.data.map((item) => {
              if (item.subdomainName) {
                list.push({ subdomainName: item.subdomainName });
              }
            });

            // 去重复对象
            var result = [];
            var obj = {};
            for (var i = 0; i < list.length; i++) {
              if (!obj[list[i].subdomainName]) {
                result.push(list[i]);
                obj[list[i].subdomainName] = true;
              }
            }
            this.answerList = result;
            // 将属性为subdomainName相同的元素组合起来
            this.answerList.map((listItem) => {
              listItem.options = [];
              listItem.number = 0;
              listItem.checkedNumber = 0;
              resp.data.data.map((item) => {
                if (item.subdomainName == listItem.subdomainName) {
                  listItem.options.push(item); //再嵌套一层
                  // listItem.number = listItem.number + 1;  //subdomainName的个数
                }
                // 保存以后，显示已保存的选项
                if (item.selectValue != "") {
                  item.lstOptions.map((sonItem) => {
                    if (item.selectValue == sonItem.options.split("、")[0]) {
                      sonItem.checked = true;
                    }
                  });
                }
                //还没保存时，界面切换时显示历史选项
                this.checkedList.map((checkedItem) => {
                  if (item.title == checkedItem.title) {
                    item.lstOptions.map((sonItem) => {
                      if (
                        checkedItem.SelectValue ==
                        sonItem.options.split("、")[0]
                      ) {
                        sonItem.checked = true;
                      }
                    });
                  }
                });
              });
            });
            this.$forceUpdate();
          }
        })
        .catch();
    },
    clickText(lstOptions, son, title, answerGuId, cutStatus) {
      if (cutStatus == 1) {
        this.$message({
          message: "此项为不可选状态",
          type: "warning",
        });
        return;
      }
      // 给每个孙子加个是否选中的属性
      if (!son.checked) {
        lstOptions.map((item) => {
          item.checked = false;
        });
        son.checked = true;
        // 将选中后的选项截取开头字母
        var option = son.options.split("、")[0];
        //判断列表的长度是否为空，否则不进入循环
        if (this.checkedList.length == 0) {
          this.checkedList.push({
            AnswerGuId: answerGuId,
            title: title,
            SelectValue: option,
          });
        } else {
          var have = false;
          this.checkedList.map((item) => {
            // 判断是否有重复的选项，有就覆盖答案，否则直接添加
            if (item.title == title) {
              item.SelectValue = option;
              have = true;
            }
          });
          if (!have) {
            this.checkedList.push({
              AnswerGuId: answerGuId,
              title: title,
              SelectValue: option,
            });
          }
        }
      } else {
        son.checked = false;
        this.checkedList.map((item, index) => {
          if (item.title == title) {
            this.checkedList.splice(index, 1);
          }
        });
      }
      // 计算每大类选中的个数
      var a = 0;
      this.answerList.map((ListItem) => {
        ListItem.options.map((childItem) => {
          childItem.lstOptions.map((sonItem) => {
            if (sonItem.checked) {
              a = a + 1;
            }
          });
        });
      });
      this.typeList.map((item) => {
        if (item.sort == this.sort) {
          //大类选中的个数
          item.checkTypeNumber = a;
        }
      });
      this.$forceUpdate();
    },
    // 保存
    keepData() {
      if (this.stopExecution) {
        this.$message({
          message: "请求中,请稍等...",
          type: "warning",
        });
        return;
      }
      if (this.checkedList.length == 0) {
        this.$message({
          message: "未选中任何选项",
          type: "warning",
        });
        return;
      }
      this.stopExecution = true;
      post("/AnswerBank", {
        data: JSON.stringify(this.checkedList),
      })
        .then((resp) => {
          this.stopExecution = false;
          if (resp.data.code == 200) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.clickType(this.sort, this.typeIndex);
          } else {
            this.$message.error(resp.data.message);
          }
        })
        .catch(() => {
          this.stopExecution = false;
          this.$message.error("添加失败");
        });
    },
    submitData() {
      if (this.stopExecution) {
        this.$message({
          message: "请求中,请稍等...",
          type: "warning",
        });
        return;
      }
      if (this.checkedList.length != this.dataLength) {
        this.$message({
          message: "未完成" + this.levels + "所有选项，不得提交",
          type: "warning",
        });
        return;
      }
      this.stopExecution = true;
      put("/AnswerBank", {
        data: JSON.stringify(this.checkedList),
      })
        .then((resp) => {
          this.stopExecution = false;
          if (resp.data.code == 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.getTypeInfoAll();
          } else if (resp.data.code == 201) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            localStorage.setItem(
              "answerGuId",
              JSON.stringify(this.checkedList[0].AnswerGuId)
            );
            localStorage.setItem(
              "answerStatus",
              JSON.stringify(resp.data.code)
            );
            this.$router.push("./indexreport");
          }
        })
        .catch(() => {
          this.stopExecution = false;
          this.$message.error("提交失败");
        });
    },
  },
};
</script>
  
  <style>
.answer-css .answer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
/* 左边等级 */
.answer-css .grade-box {
  display: inline-block;
  background-color: white;
  padding-bottom: 50px;
  padding-top: 10px;
}
.answer-css .grade-box > img {
  width: 170px;
}
.answer-css .grade {
  overflow: hidden;
  border: 1px solid rgb(119, 119, 119);
  width: 110px;
  padding-bottom: 15px;
  margin-left: 22px;
  margin-top: 15px;
  border-style: dashed;
  position: relative;
  z-index: 999;
}
.answer-css .grade .background {
  height: 2px;
  transform: rotate(-178deg);
  -ms-transform: rotate(-178deg);
  -moz-transform: rotate(-178deg);
  -webkit-transform: rotate(-178deg);
  -o-transform: rotate(-178deg);
  position: absolute;
  z-index: 0;
  width: 90px;
  padding: 9px;
  margin-top: -2px;
  margin-left: 1px;
  background-color: rgba(192, 192, 192, 0.404);
  border: none;
}
.answer-css .grade .item {
  position: relative;
  padding: 5px 0;
  transform: rotate(-15deg);
  -ms-transform: rotate(-15deg); /* Internet Explorer */
  -moz-transform: rotate(-15deg); /* Firefox */
  -webkit-transform: rotate(-15deg); /* Safari 和 Chrome */
  -o-transform: rotate(-15deg); /* Opera */
  border: 1px solid #7e7e7e;
  width: 150px;
  margin-top: 15px;
  margin-left: -20px;
  text-align: center;
  background-color: #c0c0c0d8;
  z-index: 999;
}
.answer-css .grade .item > img {
  width: 12px;
  position: absolute;
  right: 31px;
  margin-top: 2px;
  transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -webkit-transform: rotate(15deg);
  -o-transform: rotate(15deg);
}
.answer-css .grade .item span {
  color: white;
  font-weight: bold;
}
/* 右边答题 */
.answer-css .answer-box {
  width: 75%;
  background-color: white;
  margin-left: 10px;
  padding: 10px 30px;
}
.answer-css .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.answer-css .title > p {
  font-size: 20px;
  font-weight: bold;
}
.answer-css .title .info {
  display: flex;
}
.answer-css .title .poin1 {
  background-color: #666;
}
.answer-css .title .info .poin2 {
  background-color: #5a8bff;
}
.answer-css .title .info .poin3 {
  background-color: #ff9307;
}
.answer-css .info > div {
  margin-left: 30px;
}

.answer-css .title .poin {
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  margin-right: 7px;
  border-radius: 20px;
}
/* 箭头 */
.answer-css .upgrade {
  display: flex;
  margin-left: 32px;
}
.answer-css .upgrade .item {
  display: flex;
}
.answer-css .upgrade .item .text {
  position: relative;
  width: 100px;
  background: #dddddd;
  margin-left: -32px;
  display: flex;
  align-items: center;
}
.answer-css .upgrade .triangle {
  border: 19px solid transparent;
  border-left: 20px solid #dddddd;
  width: 0;
  height: 0px;
  z-index: 999;
}
.answer-css .text > span {
  position: absolute;
  border: 19px solid transparent;
  border-left: 20px solid white;
}
.answer-css .text > div {
  width: 100%;
  position: absolute;
  right: -38px;
}
.answer-css .text > div > span:nth-of-type(1) {
  margin-left: -9px;
}
.answer-css .text > div > span:nth-of-type(2) {
  margin-left: 5px;
}
/* 答题 */
.answer-css .question-box {
  margin-top: 15px;
}
.answer-css .question-box .item {
  height: 450px;
  overflow-y: scroll;
}
.answer-css .question-box .item p {
  font-weight: bold;
  font-size: 13px;
}
.answer-css .question-box .contian {
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
}
.answer-css .question-box .contian p {
  background-color: #e5e5e5;
  padding: 10px 20px;
  margin-top: 0;
  font-weight: bold;
  font-size: 13px;
}
.answer .el-radio-button__inner,
.el-radio-group {
  display: block;
  display: flex;
}
.answer-css .el-radio {
  align-items: center;
  margin: 10px 0;
  padding-left: 20px;
}
.answer-css label span {
  color: black !important;
}
.answer-css .button-box {
  width: 100%;
  text-align: center;
  margin-top: 12px;
}
.answer-css .button-box .el-button {
  margin: 10px;
  padding: 10px 25px !important;
}
.checked {
  position: absolute;
  border: 1px solid;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  left: 14px;
  top: -2px;
  color: red;
}
.question-box .box {
  position: relative;
  padding-left: 20px;
}
.question-box .box > div {
  cursor: pointer;
}
.question-box .box > div:hover {
  color: #5a8bff;
}
.question-box .box .text {
  margin: 15px 0;
}

.activeOrage {
  background-color: #ff9307 !important;
  color: white !important;
}
.triangleeOrage {
  border-left: 20px solid #ff9307 !important;
}
.activeBlue {
  background-color: #5a8bff !important;
  color: white !important;
}
.triangleeBlue {
  border-left: 20px solid #5a8bff !important;
}
.diabaleColor {
  color: #d6d3d3 !important;
}
</style>