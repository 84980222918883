var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"answer-css"},[_c('div',{staticClass:"answer"},[_c('div',{staticClass:"grade-box"},[_c('img',{attrs:{"src":require("../../assets/evaluation/img9.png")}}),_c('div',{staticClass:"grade"},_vm._l((_vm.LevelsList),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"item",class:[
                {
                  activeBlue: _vm.levelsIndex > index,
                  activeOrage: _vm.levelsIndex == index,
                },
              ]},[_c('span',[_vm._v(_vm._s(item.levels))]),(_vm.levelsIndex < index)?_c('img',{attrs:{"src":require("../../assets/evaluation/lock.png")}}):_vm._e()]),(index != _vm.LevelsList.length - 1)?_c('div',{staticClass:"background"}):_vm._e()])}),0)]),_c('div',{staticClass:"answer-box"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(_vm._s(_vm.levels)+"答题表")]),_vm._m(0)]),_c('div',{staticClass:"upgrade"},_vm._l((_vm.typeList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"text",class:[
                {
                  activeBlue: item.checkTypeNumber == item.number,
                  activeOrage: _vm.typeIndex == index,
                },
              ],on:{"click":function($event){return _vm.clickType(item.sort, index)}}},[(index != 0)?_c('span'):_vm._e(),_c('div',[_c('span',[_vm._v(_vm._s(item.sort))]),_c('span',[_vm._v(_vm._s(item.checkTypeNumber)+"/"+_vm._s(item.number))])])]),_c('span',{staticClass:"triangle",class:{
                triangleeBlue: item.checkTypeNumber == item.number,
                triangleeOrage: index == _vm.typeIndex,
              }})])}),0),_c('div',{staticClass:"question-box"},[_c('div',{staticClass:"item",attrs:{"id":"answer-contain"}},_vm._l((_vm.answerList),function(item,index){return _c('div',{key:index},[_c('p',[_vm._v(_vm._s(item.subdomainName))]),_vm._l((item.options),function(child,childIndex){return _c('div',{key:childIndex,staticClass:"contian",class:{ diabaleColor: child.cutStatus == 1 }},[_c('p',[_vm._v(_vm._s(child.title))]),_vm._l((child.lstOptions),function(son,sonIndex){return _c('div',{key:sonIndex,staticClass:"box"},[(son.checked)?_c('span',{staticClass:"checked"}):_vm._e(),_c('div',{staticClass:"text",on:{"click":function($event){return _vm.clickText(
                        child.lstOptions,
                        son,
                        child.title,
                        child.answerGuId,
                        child.cutStatus
                      )}}},[_vm._v(_vm._s(son.options))])])})],2)})],2)}),0)]),_c('div',{staticClass:"button-box"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.keepData}},[_vm._v("保存")]),_c('el-button',{attrs:{"type":"warning"},on:{"click":_vm.submitData}},[_vm._v("提交")]),_c('router-link',{attrs:{"tag":"el-button","to":"./historypage","type":"info","plain":""}},[_vm._v("退出")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',[_c('span',{staticClass:"poin poin1"}),_c('span',[_vm._v("未完成")])]),_c('div',[_c('span',{staticClass:"poin poin2"}),_c('span',[_vm._v("已完成")])]),_c('div',[_c('span',{staticClass:"poin poin3"}),_c('span',[_vm._v("当前正处于")])])])
}]

export { render, staticRenderFns }